import React, { useMemo, useState, useEffect } from "react";
import useFilteredArticles from "../../../../../../hooks/useFilteredArticles";
import Card from "../../../../../Card";
import { ReactComponent as Logo } from "../../../../../../assets/images/branding_logo.svg";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide} from 'swiper/react';
import { Navigation } from 'swiper/modules';
import Button from "../../../../../Button";
import 'swiper/css';
import 'swiper/css/navigation';

const animateIn = {
  initial: { opacity: 0, x: -50 },
  intro: {
    opacity: 1,
    x: 0,
  },
  outro: {
    opacity: 0,
    x: -50,
  },
};

export const ArticleSlider = ({ content, req }) => {
  const tags = useMemo(
    () => content.filtertags.split(","),
    [content.filtertags]
  );

  const { availableArticles } = useFilteredArticles(tags);
  const articlesArray = availableArticles;
  const [ref, isVisible] = useInView({ threshold: 0.1 });
  const [sortedArticles, setSortedArticles] = useState([]);

  const swiperBreakpoints = {
    2500: {
      slidesPerView: 6.5,
    },
    1920: {
      slidesPerView: 5.6,
      slidesOffsetBefore: 30
    },
    1440: {
      slidesPerView: 4.7,
      slidesOffsetBefore: 24
    },
    1024: {
      slidesPerView: 3.5,
    },
    640: {
      slidesPerView: 2.6,
    },
    0: {
      slidesPerView: 1.4,
    }
  };

  useEffect(() => {
    const sortedArray = [...articlesArray].sort((a, b) => {
      const pinnedA = a.pinned === "true";
      const pinnedB = b.pinned === "true";

      return pinnedB - pinnedA;
    });

    setSortedArticles(sortedArray.slice(0, 20));
  }, [articlesArray]);

  return (
    content && (
      <section className="article-slider">
        <div className="article-slider__header headline">
          <div className="headline__logo">
            <Logo></Logo>
          </div>
          {tags.map((tag, i) => (
            <div key={i} className="headline__tags button button--default">
              <i className="icon icon-cross"></i>
              <span>{tag}</span>
            </div>
          ))}
        </div>
        <motion.div
          className="carousel--container"
          ref={ref}
          initial="initial"
          variants={animateIn}
          animate={isVisible ? "intro" : false}
          transition={{
            delay: 0,
            duration: 0.5,
            ease: [0.2, 0, 0, 1],
          }}
        >
          <Swiper
            modules={[Navigation]}
            navigation={{
              nextEl: ".carousel--button__next",
              prevEl: ".carousel--button__prev",
              hideOnClick: true,
              disabledClass: "carousel--button__hide",
            }}
            spaceBetween={30}
            breakpoints={swiperBreakpoints}
            slidesOffsetBefore={24}
            slidesOffsetAfter={20}
          >
            {sortedArticles &&
              sortedArticles.map((article) => (
                <SwiperSlide key={article.uri}>
                  <Card article={article}></Card>
                </SwiperSlide>
              ))}
          <div className={`carousel--button__group`}>
              <Button
                icon="arrow"
                className={`carousel--button__next`}
              />
              <Button
                icon="arrow"
                className={`carousel--button__prev`}
              />
          </div>
          </Swiper>
        </motion.div>
      </section>
    )
  );
};
