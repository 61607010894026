import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ArticleGrid from "./components/ArticleGrid";
import Article from "./components/Article";
import Default from "./pages/Default";
import SearchResultsPage from "./pages/Search";
import { useTags } from "./contexts/SelectedTags";
import { useApplication } from "./contexts/Application";
import { AnimatePresence } from "framer-motion";
import { useLanguage } from "./hooks/useLanguage";
// import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import CookieConsent from "./components/CookieConsent";
import { useSearch } from "./contexts/Search";

const __DEV__ = process.env.NODE_ENV === "development";

// Function for checking if landingpages contain all of the tags
const equals = (a, b) => {
  const upperA = a.map((tag) => tag.toUpperCase());
  const upperB = b.map((tag) => tag.toUpperCase());
  if (upperA.length !== upperB.length) return false;
  const uniqueValues = new Set([...upperA, ...upperB]);
  for (const v of uniqueValues) {
    const aCount = upperA.filter((e) => e === v).length;
    const bCount = upperB.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

export const Router = () => {
  const { site } = useApplication();
  const { pathname } = useLocation();

  // // Google Analytics hook
  // const useAnalytics = useGoogleAnalytics();
  // if (!__DEV__) {
  //   useAnalytics;
  // }

  const { selectedTags, setSelectedTags } = useTags();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const history = useNavigate();
  const { currentLanguage } = useLanguage();
  const { isSearch, updateIsSearch } = useSearch();

  const [availableLandingPages, setAvailableLandingPages] = useState(null);
  const [isLandingPage, setIsLandingPage] = useState(false);
  const [landingPageData, setLandingPageData] = useState(null);
  const [isArticle, setIsArticle] = useState(false);
  const [hasPath, setHasPath] = useState(false);

  //
  // HANDLE URL AND SELECTED TAGS CONNECTION

  // When we select or remove a tag we want to add that tag to the URL
  const pushTags = () => {
    const filterParams = selectedTags.toString().replace(",", "/");
    !pathname.includes("articles") && !searchParams.get('search') && history(`/${filterParams}`);
    // if the page is an article page we need to set
  };

  const urlToTags = () => {
    const pathTags = pathname
      .substring(1) // remove base path slash
      .replace("/", ",")
      .split(",")
      .map((tag) => decodeURI(tag));
    !pathname.includes("articles") && pathname.length >= 2
      ? setSelectedTags(pathTags)
      : "";
  };

  useEffect(() => {
    pushTags();
  }, [selectedTags]);

  // On page load add tags in url to selected Tags Context
  useEffect(() => {
    urlToTags();
    setHasPath(pathname.length > 1);
    setIsArticle(pathname.includes("articles"));
    updateIsSearch(!!searchParams.get('search'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);
  
  // On change of tags run landingpagefilter
  useEffect(() => {
    let filteredLandingPages = [...site.landingpages, ...site.static].filter( (item) =>
      equals(selectedTags, item.landingtags)
    );
    setAvailableLandingPages(filteredLandingPages);
  }, [site, selectedTags]);
  
  useEffect(() => {
    setIsLandingPage(availableLandingPages && availableLandingPages.length >= 1);
    setLandingPageData(availableLandingPages && availableLandingPages[0]);
  }, [availableLandingPages]);


  //const hasLang = location.pathname.includes(currentLanguage.code) ? "/" + currentLanguage.code : "";
  //console.log('lang', hasLang);


  return (
    <>
      <Header></Header>
      <div className="holder">
        {/* {location && (
          <AnimatePresence
            mmode="wait"
            initial={false}
            onExitComplete={() => window.scrollTo(0, 0)}
            key={ 'router' }
          > */}
        <Routes location={location} key={pathname}>
          {/* STATIC ROUTES GO HERE */}
          {/* For custom build pages with own components. 
              Use the exact prop.
              --- Example: 
              <Route path="/contact" exact>
                <Static></Static>
              </Route> testcode: <Route path={hasLang + "/articles"}>*/}

          {/* / STATIC ROUTES */}
          {["zoeken", "search"].map(
            (path, i) => (
              <Route
                key={i}
                path={path}
                element={
                  <AnimatePresence
                    mode="wait"
                    initial={false}
                    onExitComplete={() => window.scrollTo(0, 0)}
                    key={"routerPath"}
                  >
                    {isLandingPage && (
                      <div>
                        <SearchResultsPage
                          landingPage={landingPageData.uri}
                          key={i}
                        ></SearchResultsPage>
                      </div>
                    )}
                  </AnimatePresence>
                }
              />
            )
          )}
          <Route
            path="*"
            element={
              <AnimatePresence
                mode="wait"
                initial={false}
                onExitComplete={() => window.scrollTo(0, 0)}
                key={"routerPath"}
              >
                {isLandingPage && (
                  <Default
                    landingPage={landingPageData.uri}
                    key={landingPageData.id}
                  ></Default>
                )}
                {isArticle && <Article></Article>}
                {pathname.length > 1 && !isLandingPage && !isArticle && (
                  <ArticleGrid></ArticleGrid>
                )}
                {!hasPath && selectedTags < 1 && (
                  <Default landingPage="home"></Default>
                )}
              </AnimatePresence>
            }
          ></Route>
        </Routes>
        <Footer></Footer>
        <CookieConsent></CookieConsent>
        {/* </AnimatePresence>
        )} */}
      </div>
    </>
  );
};

export default Router;
