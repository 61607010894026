import React, { useState , useEffect } from "react";
import { useCookies } from 'react-cookie';
import NeedConsent from "../../../../NeedConsent";

const Video = ({ content }) => {
  const { url, caption } = content;
  const [cookies, _] = useCookies([process.env.COOKIE_NAME]);
  const [showVideo, setShowVideo] = useState(false);

  const createEmbedUrl = (url) => {
    var regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return `https://youtube.com/embed/${match[2]}?modestbranding=1&rel=0`;
    } else {
      console.warn("Could not create embed url");
      return null;
    }
  };

  useEffect(() => {
    if (cookies && cookies[process.env.COOKIE_NAME]?.consent ) {
      setShowVideo(true);
    } else {
      setShowVideo(false);
    }
  }, [cookies]);

  const embedUrl = createEmbedUrl(url);

  return (
    embedUrl && (
      <div className="video--holder">
        <figure>
          { showVideo && (
            <iframe
              width="100%"
              height="100%"
              src={`${embedUrl}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; picture-in-picture"
              allowFullScreen
            />
          )}

          { !showVideo && (
            <NeedConsent />
          )}
          {caption && <figcaption><small>{caption}</small></figcaption>}
        </figure>
      </div>
    )
  );
};

export default Video;
