import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import Button from "../Button";
import Headline from "../Headline";
import { FilterNavbar } from "../FilterNavbar/FilterNavbar";
import { useApplication } from "../../contexts/Application";
import { useTags } from "../../contexts/SelectedTags";
import SearchForm from "../SearchForm";
import { useSearch } from "../../contexts/Search";

const Header = ({ applyBtn, toggleBtn, backgroundColor }) => {
  const { site } = useApplication();
  const history = useNavigate();
  const { selectedTags, filterOpen, setFilterOpen } = useTags();
  const { searchOpen, updateSearchOpen, isSearch } = useSearch();
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);

  const handleScroll = () => {
    if (ref.current) {
      setSticky(window.pageYOffset >= 90);
    }
  };

  const setFilterMenu = () => {
    window.scrollTo(0, 0);
    setFilterOpen((prevState) => !prevState);
    if (searchOpen) {
      updateSearchOpen(false);
    }
  };

  const setSearchMenu = () => {
    window.scrollTo(0, 0);
    updateSearchOpen((prevState) => !prevState);
    if (filterOpen) {
      setFilterOpen(false);
    }
  };

  const resetMenu = () => {
    setFilterOpen(false);
    updateSearchOpen(false);
  };

  // Set up the base Tags
  const GeneralTags = useMemo(
    () => [
      {
        title: site.generallabel,
        buttons: [site.tags.generaltags],
      },
    ],
    [site.tags.generaltags, site.generallabel]
  );

  const EventTags = useMemo(
    () => [
      {
        title: site.eventslabel,
        buttons: [site.tags.eventTags],
      },
    ],
    [site.tags.eventTags, site.eventslabel]
  );

  const DisciplinesTags = useMemo(
    () => [
      {
        title: site.disciplineslabel,
        buttons: [site.tags.disciplinestags],
      },
    ],
    [site.tags.disciplinestags, site.disciplineslabel]
  );

  const ThemesTags = useMemo(
    () => [
      {
        title: site.themeslabel,
        buttons: [site.tags.themestags],
      },
    ],
    [site.tags.themestags, site.themeslabel]
  );

  const AboutTags = useMemo(
    () => [
      {
        title: site.aboutlabel,
        buttons: [site.tags.abouttags],
      },
    ],
    [site.tags.abouttags, site.aboutlabel]
  );

  const baseTags = useMemo(
    () => [
      { ...GeneralTags },
      { ...ThemesTags },
      { ...EventTags },
      { ...DisciplinesTags },
      { ...AboutTags },
    ],
    [EventTags, DisciplinesTags, GeneralTags, ThemesTags, AboutTags]
  );

  useEffect(() => {
    setTags(baseTags);
  }, [baseTags, site.language]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  const [tags, setTags] = useState(baseTags);

  return (
    <>
      <div className={`header ${isSticky ? " sticky" : ""}`} ref={ref}>
        <Headline onClick={resetMenu} open={filterOpen || searchOpen} />
        <div className="header__btn--toggle">
          <Button
            className={`button--search ${searchOpen ? "button--active" : ""}`}
            icon={"search"}
            label={""}
            onClick={setSearchMenu}
            active={searchOpen}
          />
          {!isSearch && (
            <Button
              className={`button--filter ${filterOpen ? "button--active" : ""}`}
              icon={"plus"}
              label={"filter"}
              onClick={setFilterMenu}
              active={filterOpen}
            />
          )}
        </div>
      </div>
      <FilterNavbar
        tags={tags}
        open={filterOpen}
        setOpen={setFilterOpen}
      ></FilterNavbar>
      <SearchForm open={searchOpen}></SearchForm>
    </>
  );
};

export default Header;
