import React from "react";
import { Link } from "react-router-dom";
import Badge from "../Badge";
import { AspectImage } from "../Image";
import { useTags } from "../../contexts/SelectedTags";

const Card = ({ article, ratioSize }) => {
  const card = article;
  const { setSelectedTags } = useTags();

  // Merge the Tags from the article
  const tags = card.generaltags.concat(
    card.eventTags,
    card.themestags,
    card.disciplinestags,
    card.abouttags
  );

  // Check if the article type is a Case
  const isCase = tags && tags.includes("Case");

  // We need smaller aspect ratio's in the Article Grid view
  const ratioIsSmall = ratioSize == "small";

  // Create the Aspect ratio image object
  const cardImage = {
    url: card.image,
    ratio: ratioIsSmall ? (isCase ? "1/1" : "5/4") : isCase ? "10/13" : "10/11",
  };

  // We need to update selectedTags for routing to work
  const handleClick = () => {
    setSelectedTags([]);
  };

  return (
    <>
      <Link
        to={`/${card.uri}`}
        onClick={handleClick}
        className={`card ${isCase ? "card--case" : ""}`}
      >
        <div
          className={`card__container bg-${card.pagecolor}`}
          style={{ backgroundColor: `hsla(var(--${card.pagecolor}))` }}
        >
          <AspectImage image={cardImage} alt={"#"} />
          {isCase && <div className="image--overlay"></div>}

          <div className="card__container__inner">
            {tags && (
              <div className="card__container__tags scrollbar-hidden">
                {tags.map((badge, i) => (
                  <Badge className="" key={i} icon={badge.icon} label={badge} />
                ))}
              </div>
            )}
            <div className="card__container__title">
              {isCase && card.innertitle && (
                <h3 className="">{card.innertitle}</h3>
              )}
              {isCase && card.innersubtitle && (
                <p className="">{card.innersubtitle}</p>
              )}
              {!cardImage.url && card.title && (
                <h3 className="">{card.title}</h3>
              )}
            </div>
          </div>
        </div>
        {cardImage.url && card.title && <h3>{card.title}</h3>}
      </Link>
    </>
  );
};

export default Card;
