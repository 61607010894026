import React, { useState, useEffect, useMemo } from "react";
//import rehypeFilter from "react-markdown/lib/rehype-filter";
import { useApplication } from "../contexts/Application";

// For checking if articles contain any of the applied tags
const cleanUpString = (string) => {
  return string.replace(/\s+/g, "").toLowerCase();
};

const contains = (orig, filter) => {
  let filterMatchCount = 0;

  orig.forEach((item, key) => {
    orig[key] = cleanUpString(item);
  });

  filter.forEach((item, key) => {
    if (orig.indexOf(cleanUpString(item)) > -1) {
      filterMatchCount++;
    }
  });

  return filterMatchCount === filter.length;
};

export const useFilteredArticles = (filterInput) => {
  const { site } = useApplication();
  const { articles } = site;

  const [availableArticles, setAvailableArticles] = useState(articles);
  useEffect(() => {
    // Filter the available articles
    let filteredArticles = articles.filter((item) =>
      contains(
        item.generaltags.concat(
          item.eventTags,
          item.themestags,
          item.disciplinestags,
          item.abouttags
        ),
        filterInput
      )
    );
    setAvailableArticles(filteredArticles);
  }, [articles, filterInput]);

  return { availableArticles };
};

export default useFilteredArticles;
