import React, { useState, useEffect } from "react";
import { useApplication } from "./contexts/Application";
import { useLanguage } from "./hooks/useLanguage";
import Router from "./router";

const App = () => {
  document.title = "CreativeNL";
  const { site } = useApplication();
  const { currentLanguage } = useLanguage();

  return (
    <>
      {site && (
        <div className="wrapper" id="wrapper">
          <Router></Router>
        </div>
      )}
    </>
  );
};

export default App;
