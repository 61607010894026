import React, { useState, useEffect } from "react";
import useFilteredPages from "../../hooks/useFilteredPages";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";

import Card from "../Card";

export const ArticleGrid = () => {
  const { availableArticles } = useFilteredPages();
  const Articles = availableArticles;

  const [columns, setColumns] = useState(3);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    document.body.style.setProperty("--theme-color", `var(--blue)`);
  }, []);

  // Reorder the Article array
  useEffect(() => {
    const reorder = (articles, columns) => {
      const cols = columns;
      const arr = articles;
      const out = [];
      let col = 0;
      while (col < cols) {
        for (let i = 0; i < arr.length; i += cols) {
          let _val = arr[i + col];
          if (_val !== undefined) out.push(_val);
        }
        col++;
      }
      setCards(out);
      setColumns(columns);
    };
    reorder(Articles, columns);
  }, [Articles, columns]);

  return (
    cards &&
    cards.length >= 1 && (
      <div className="page-holder">
        <div className="article-grid">
          <div className="article-grid--columns">
            <LayoutGroup type="crossfade">
              {cards &&
                cards.map((article, i) => (
                  <AnimatedCard
                    key={article.uri}
                    article={article}
                    layoutId={article.uri}
                    layout
                  ></AnimatedCard>
                ))}
            </LayoutGroup>
          </div>
        </div>
      </div>
    )
  );
};

const AnimatedCard = ({ article }) => {
  const cardAnim = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        ease: [0.2, 0, 0, 1],
      },
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <motion.div
      layout
      className="card--holder"
      initial="initial"
      animate="animate"
      variants={cardAnim}
    >
      <Card
        className="card article-grid--card"
        article={article}
        ratioSize=""
        layout
      />
    </motion.div>
  );
};

export default ArticleGrid;
