import React from "react";
import { useTags } from "../../contexts/SelectedTags";
import useFilteredPages from "../../hooks/useFilteredPages";
import Button from "../Button";
import { motion } from "framer-motion";

export const TagButton = ({ button }) => {
  const { selectedTags, setSelectedTag, removeSelectedTag } = useTags();
  const { availableTags } = useFilteredPages();

  // If tags are clicked check rest availibility
  // in the site & then add the selectedtag to the url

  const HandleClick = (button) => {
    if (selectedTags.includes(button)) {
      removeSelectedTag(button);
    } else {
      setSelectedTag(button);
    }
  };

  const isActive = (button) => {
    if (selectedTags.includes(button)) {
      return true;
    }
  };

  const isDisabled = (button) => {
    if (availableTags.includes(button)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Button
      id={button}
      className={`button--filter`}
      disabled={`${
        availableTags && isDisabled(button) ? "button--disabled" : ""
      }`}
      icon="plus"
      label={button}
      active={isActive(button)}
      onClick={() => HandleClick(button)}
    ></Button>
  );
};
