import React, { useEffect, useRef } from "react";
import Button from "../Button";
import { useTags } from "../../contexts/SelectedTags";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { ReactComponent as Logo } from "../../assets/images/branding_logo.svg";
import { useSearch } from "../../contexts/Search";

const Headline = ({ onClick, open }) => {
  const { selectedTags, removeSelectedTags, removeSelectedTag } = useTags();
  const { isSearch, resetSearchValue } = useSearch();
  const navigate = useNavigate();

  const homeClick = () => {
    removeSelectedTags();
    resetSearchValue();
    {
      open && onClick();
    }
  };

  const removeSearch = () => {
    homeClick();
    navigate("/");
  };

  const maskTags = () => {
    // Set mask when tags are obverflowing
    const tags = document.querySelector("#tags-holder");
    const logo = document.querySelector("#logo");
    const headlineButton = document.querySelector("#headline-button");
    tags &&
      logo &&
      headlineButton &&
      window.innerWidth - 80 - logo.offsetWidth - headlineButton.offsetWidth <
        tags.scrollWidth &&
      tags.classList.toggle("mask");
  };

  const TagAnim = {
    initial: {
      x: -30,
      opacity: 0,
      transition: { duration: 0.3 },
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.3 },
    },
    exit: {
      x: -30,
      opacity: 0,
      transition: { duration: 0.3 },
    },
  };

  //

  return (
    <div className="headline" id="headline">
      <Link to="/" onClick={homeClick}>
        <div className="headline__logo" id="logo">
          <Logo></Logo>
        </div>
      </Link>
      <LayoutGroup type="crossfade">
        <motion.div
          className="headline__tags--holder mask scrollbar-hidden"
          id="tags-holder"
          layout
        >
          <AnimatePresence>
            {selectedTags &&
              selectedTags.length > 0 &&
              selectedTags.map((tag, i) => (
                <motion.div
                  key={i}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={TagAnim}
                  className="headline__tags button button--default"
                  onClick={() =>
                    !isSearch ? removeSelectedTag(tag) : removeSearch()
                  }
                  onAnimationComplete={() => maskTags()}
                  layout
                >
                  <i className="icon icon-cross"></i>
                  <span className="headline__tags--item">{tag}</span>
                </motion.div>
              ))}
          </AnimatePresence>
          <AnimatePresence>
            {selectedTags && selectedTags.length > 0 && open && !isSearch && (
              <motion.div layout>
                <div className="headline--button" id="headline-button">
                  <Button
                    className={`button--circle`}
                    icon={"arrow"}
                    onClick={onClick}
                  ></Button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </LayoutGroup>
    </div>
  );
};

export default Headline;
