import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const animateIn = {
  initial: { opacity: 0, y: 100},
  intro: {
    opacity: 1,
    y: 0
  },
  outro: {
    opacity: 0,
    y: 100
  },
};

const HeroWatermark = ({ text }) => {

  const [ref, isVisible] = useInView({ threshold: 0.1 });

  return (
    <motion.div
            className="hero-watermark"
            ref={ref}
            initial="initial"
            variants={animateIn}
            animate={isVisible ? "intro" : "outro"}
            transition={{
              delay: 0,
              duration: 1,
              ease: [0.2, 0, 0, 1]
            }}
          >
      <div className="hero-watermark__overlay"></div>
      <div className="hero-watermark__content display-1">{text}</div>
    </motion.div>
  );
};

const LandingpageHero = ({ content }) => {
  const [ref, isVisible] = useInView({ threshold: 0.1 });

  return (
    content && (
      <div className="hero-landingpage grid p--rel overflow--clip">
        <HeroWatermark text={content.watermark} />
        <div className="hero-landingpage__offset display-1">{content.watermark}</div>
        <motion.div
            className="hero-landingpage__content pb--default"
            ref={ref}
            initial="initial"
            variants={animateIn}
            animate={isVisible ? "intro" : "outro"}
            transition={{
              delay: 0.2,
              duration: 1,
              ease: [0.2, 0, 0, 1]
            }}
          >
          <h1 className="hero-landingpage__content-title display-2">{content.title}</h1>
          <p className="hero-landingpage__content-subtitle">
            {content.subtitle}
          </p>
        </motion.div>
      </div>
    )
  );
};

export default LandingpageHero;
