import { useState, useMemo } from "react";
import { useApplication } from "../contexts/Application";

// Custom hook for filtering search results
const useSearchFilter = (param) => {
  const { site } = useApplication();
  const { articles } = site;

  // Memoized filtered results based on query
  const filteredResults = useMemo(() => {
    return articles.filter(
      (article) =>
        !!article.title?.toLowerCase().includes(param.toLowerCase()) ||
        !!article.subtitle?.toLowerCase().includes(param.toLowerCase())
    );
  }, [articles, param]);

  return {
    filteredResults,
  };
};

export default useSearchFilter;
