import classNames from "classnames";
import React from "react";

const Badge = ({ icon = "cross--sm", label, className }) => {
  const cls = classNames({
    icon: true,
    ["icon-" + icon]: !!icon,
  });

  return (
    <div className={`badge ${className}`}>
      {icon && <i className={cls}></i>}
      {label && <h5>{label}</h5>}
    </div>
  );
};

export default Badge;
