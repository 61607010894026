import React from "react";
import { Link } from "react-router-dom";
import Socials from "../../../../../Socials";
import { useTags } from "../../../../../../contexts/SelectedTags";

const HeroCTA = ({ content }) => {
const { setSelectedTags } = useTags();
// We need to update selectedTags for routing to work
const handleClick = () => {
  setSelectedTags([]);
};

  return (
    content && (
      <div className="hero-cta">

       {content.link ? ( <Link onClick={handleClick} className="hero-cta__link" to={`${content.link.value}`}>
          <i className="icon icon-arrow_right"></i>
          <h3 className="hero-cta__link-label mb-0">{content.text}</h3>
        </Link>) : 
        <div className="hero-cta__link">
          <i className="icon icon-arrow_right"></i>
          <h3 className="hero-cta__link-label mb-0">{content.text}</h3>
        </div>}
        {content.socials.length ? (
          <div className="hero-cta__socials">
            <Socials buttons={content.socials} />
          </div>
        ) : null}
      </div>
    )
  );
};

export default HeroCTA;
