import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import KirbyRequests from "../../services/Kirby";

const initialState = {
  status: "INITIALIZING",
  site: null,
};

const Context = createContext(null);

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING": {
      return { ...state, status: "LOADING" };
    }
    case "SITE_READY": {
      return { ...state, status: "READY", site: action.payload };
    }
    case "ERROR": {
      console.warn("Error loading site", action.payload);
      return { ...state, status: "ERROR" };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

// Helper to fetch site data
const fetchSite = async (dispatch, revalidate) => {
  dispatch({ type: "LOADING" });
  try {
    const payload = await KirbyRequests.getPage("sitecontents", { revalidate });
    dispatch({ type: "SITE_READY", payload: payload });
  } catch (error) {
    dispatch({ type: "ERROR" });
  }
};

// eslint-disable-next-line react/prop-types
export const ApplicationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // If site isn't set yet, fetch the site info
  useEffect(() => {
    fetchSite(dispatch);
  }, []);

  const triggerFetch = useCallback(() => {
    fetchSite(dispatch, true);
  }, []);

  const value = {
    ...state,
    triggerFetch,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useApplication = () => {
  const contextState = useContext(Context);

  if (contextState === null) {
    throw new Error("useApplication must be used within a Provider tag");
  }

  return contextState;
};
