import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ApplicationProvider } from "./contexts/Application";
import { CookiesProvider } from "react-cookie";
import "./assets/styles/style.scss";
import { TagsProvider } from "./contexts/SelectedTags";
import { SearchProvider } from "./contexts/Search";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ApplicationProvider>
      <BrowserRouter>
        <SearchProvider>
          <TagsProvider>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </TagsProvider>
        </SearchProvider>
      </BrowserRouter>
    </ApplicationProvider>
  </React.StrictMode>
);
