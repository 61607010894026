import React, { useState, useEffect } from "react";
import { useApplication } from "../contexts/Application";
import { useTags } from "../contexts/SelectedTags";

// For checking if articles contain any of the applied tags
const contains = (orig, filter) => {
  const upperOrig = orig.map((tag) => tag.toUpperCase());
  const upperFilter = filter.map((tag) => tag.toUpperCase());
  let res = upperFilter.map((item) => {
    return upperOrig.includes(item);
  });
  return !res.includes(false);
};

export const useFilteredPages = () => {
  const { site } = useApplication();
  const { selectedTags } = useTags();

  // If site language != default language we need an additional language filter
  const { articles } = site;
  const { landingpages } = site;

  const [availableArticles, setAvailableArticles] = useState(articles);
  const [availableLandingPages, setAvailableLandingPages] = useState([
    ...landingpages,
    ...site.static,
  ]);
  const [availableTags, setAvailableTags] = useState();

  useEffect(() => {
    // Filter the available articles
    let filteredArticles = articles.filter((item) =>
      contains(
        item.generaltags.concat(
          item.eventTags,
          item.themestags,
          item.disciplinestags,
          item.abouttags
        ),
        selectedTags
      )
    );
    setAvailableArticles(filteredArticles);
    let filteredLandingPages = [...landingpages, ...site.static].filter(
      (item) => contains(item.landingtags, selectedTags)
    );
    setAvailableLandingPages(filteredLandingPages);
  }, [selectedTags, articles, landingpages, site]);

  // Filter the remaining available tags
  useEffect(() => {
    let availabletags = [
      ...new Set(
        availableArticles.flatMap((item) =>
          item.generaltags.concat(
            item.themestags,
            item.disciplinestags,
            item.eventTags,
            item.abouttags
          )
        )
      ),
    ];
    let landingtags = availableLandingPages.flatMap((item) => item.landingtags);
    setAvailableTags([...availabletags, ...landingtags]);
  }, [availableArticles, availableLandingPages]);

  return { availableArticles, availableTags };
};

export default useFilteredPages;
