import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { useSearch } from "../../contexts/Search";
import { useLanguage } from "../../hooks/useLanguage";

const SearchForm = () => {
  const { searchValue, updateSearchValue, searchOpen } = useSearch();
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const { currentLanguage } = useLanguage();

  useEffect(() => {
    if (searchOpen) {
      document.body.classList.add("search-open");
      document.querySelector("#searchbar").focus();
    } else {
      document.body.classList.remove("search-open");
    }
  }, [searchOpen]);

  useEffect(() => {
    setQuery(searchValue);
  }, [searchValue]);

  const handleSearch = () => {
      updateSearchValue(query);
      navigate(`${currentLanguage.code === 'en' ? "search" : "zoeken"}?search=${query}`);
  };

  let icon;
  icon = "arrow";

  return (
    <>
      <div className="search-navbar">
        <form
          className="search-navbar__holder"
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <input
            type="text"
            id="searchbar"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder={currentLanguage.code === "nl" ? "Zoeken..." : "Search..."}
            autoComplete="off"
          />
          <Button
            className="button--circle"
            icon={icon}
            onClick={() => {
              handleSearch();
            }}
          />
        </form>
      </div>
    </>
  );
};

export default SearchForm;
