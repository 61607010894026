import React from "react";

import Image from "./Image";
import Gallery from "./Gallery";
import Text from "./Text";
import Textarea from "./Textarea";
import Markdown from "./Markdown";
import Heading from "./Heading";
import List from "./List";
import Video from "./Video";
import LandingpageHero from "./Hero";
import LandingpageCTA from "./CTA";
import Quote from "./Quote";
import classNames from "classnames";
import { ArticleSlider } from "./ArticleSlider";
import LandingpageHeroSlider from "./HeroSlider";
//ARTICLES

const ContentBlocks = {
  image: Image,
  text: Text,
  textarea: Textarea,
  markdown: Markdown,
  heading: Heading,
  quote: Quote,
  list: List,
  video: Video,
  landingpageHero: LandingpageHero,
  landingpageHeroslider: LandingpageHeroSlider,
  landingpageCta: LandingpageCTA,
  articleslider: ArticleSlider,
};

const Blocks = (block) => {
  const cx = classNames({
    block: true,
    [`block--${block.type.toLowerCase()}`]: true,
  });

  if (typeof ContentBlocks[block.type] !== "undefined") {
    return (
      <div className={cx} key={block.id}>
        {React.createElement(ContentBlocks[block.type], {
          content: block.content,
        })}
      </div>
    );
  }
  // component doesn't exist yet
  return React.createElement(
    () => <div style={{ color: "red" }}>The component | {block.type} | has not been created yet.</div>,
    { key: block._uid }
  );
};

export default Blocks;
