import React from "react";
import { useNavigate } from "react-router";
import Button from "../Button";

const Socials = ({ buttons }) => {
  const history = useNavigate();
  const handleClick = (button) => history(`/${button.uri}`);
  return (
    <div className="socials">
      {buttons &&
        buttons.length &&
        buttons.map((button, i) => (
          <Button
            className={`button--circle`}
            key={i}
            icon={"social_" + button.icon.toLowerCase()}
            label={null}
            onClick={() => {
              window.open(button.uri, "_blank");
            }}
          />
        ))}
    </div>
  );
};

export default Socials;
