import React from "react";
import { motion } from "framer-motion";

export const Main = ({ children, className }) => {

  const variants = {
    initial: { opacity: 0 },
    intro: { 
      opacity: 1,
      transition: {
        ease: [0.2, 0, 0, 1]
      } 
    },
    outro: {
      opacity: 0,
      transition: {
        ease: [0.2, 0, 0, 1]
      } 
    },
  };

  return (
    <motion.main
      initial="initial"
      animate="intro"
      exit="outro"
      variants={variants}
      className={`page-holder ${className}`}>
      {children}
    </motion.main>
  );
};
