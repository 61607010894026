import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const animateIn = {
  initial: { opacity: 0, y: 100 },
  intro: {
    opacity: 1,
    y: 0,
  },
  outro: {
    opacity: 0,
    y: 100,
  },
};

const LandingpageHeroSlider = ({ content }) => {
  const delay = 4500;
  const slides = content.slides;
  const [ref, isVisible] = useInView({ threshold: 0.1 });

  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index, slides]);

  return (
    slides && (
      <div className="p--rel overflow--clip">
        {slides.map((slide, i) => (
          <div
            key={i}
            className={`hero-landingpage hero-landingpage__slider grid${
              index === i ? " active" : ""
            }`}
          >
            <motion.div
              className="hero-watermark"
              ref={ref}
              initial="initial"
              variants={animateIn}
              animate={`${index === i ? "intro" : "outro"}`}
              transition={{
                delay: 0,
                duration: 1,
                ease: [0.2, 0, 0, 1],
              }}
            >
              <div className="hero-watermark__overlay"></div>
              <div className="hero-watermark__content display-1">
                {slide.watermark}
              </div>
            </motion.div>
            <div className="hero-landingpage__offset display-1">
              {slide.watermark}
            </div>
            <motion.div
              className="hero-landingpage__content pb--default"
              ref={ref}
              initial="initial"
              variants={animateIn}
              animate={`${index === i ? "intro" : "outro"}`}
              transition={{
                delay: 0.2,
                duration: 1,
                ease: [0.2, 0, 0, 1],
              }}
            >
              <h1 className="hero-landingpage__content-title display-2">
                {slide.title}
              </h1>
              <p className="hero-landingpage__content-subtitle">
                {slide.subtitle}
              </p>
            </motion.div>
          </div>
        ))}
      </div>
    )
  );
};

export default LandingpageHeroSlider;
