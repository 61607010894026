import React, { useEffect } from "react";
import Button from "../Button";

export const LanguageButton = ({ buttonLanguage, isActive, handleClick }) => {
  return (
    buttonLanguage && (
      <Button
        className={`button--filter`}
        id={buttonLanguage.code}
        label={buttonLanguage.name}
        active={isActive(buttonLanguage)}
        disabled={false}
        onClick={() => handleClick(buttonLanguage)}
      ></Button>
    )
  );
};
