import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useApplication } from "../../contexts/Application";

const Footnote = ({ legal, logo = "brand_logo_round" }) => {
  const { site } = useApplication();
  const { wrapperSections } = site || { wrapperSections: [] };
  const stickerUrl = "https://www.nlplatform.com/sustainable-inclusive-entrepreneurship/top-sectors/creative-industries";
  const footnote = wrapperSections.filter(
    ({ type }) => type == "wrapperpageFootnote"
  )[0].content;

  return (
    <div className="footnote">
      {footnote && (
        <div className="footnote__legal">
          <p className="footnote__legal__copyright">{footnote.copyright}</p>
          {footnote.termslink && (<p className="footnote__legal__terms">
          <Link to={footnote.termslink.value}>{footnote.termstext}</Link>
          </p>)}
          {footnote.policylink && (<p className="footnote__legal__policy">
          <Link to={footnote.policylink.value}>{footnote.policytext}</Link>
          </p>)}
        </div>
      )}
      {logo && (
        <div className="footnote__logo">
           <Link to={{ pathname: stickerUrl }} target="_blank"><i className={"icon icon-" + logo} /></Link>
        </div>
      )}
    </div>
  );
};

export default Footnote;
