import React from "react";
import { useCookies } from 'react-cookie';
import { useApplication } from "../../../contexts/Application";

const NeedConsent = () => {
  const [_, setCookies] = useCookies([process.env.COOKIE_NAME]);

  const { site } = useApplication();
  const { wrapperSections } = site || { wrapperSections: [] };
  const cookieConsent = wrapperSections.filter(
    ({ type }) => type == "wrapperpageCookieConsent"
  )[0].content;

  console.log('cookieConsent', cookieConsent);

  const acceptCookies = () => {
    setCookies(process.env.COOKIE_NAME, { consent: true }, { path: '/' });
  };

  return (
    <div className="cookie-consent__no-consent">
      <h4>{ cookieConsent.needconsenttitle }</h4>
      <a href="#" onClick={ acceptCookies }>{ cookieConsent.needconsenttext }</a>
    </div>
  );
};

export default NeedConsent;
