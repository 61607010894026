import React from "react";
import { ReactComponent as PlusIcon } from "../../assets/images/icon/icon_plus.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/icon/icon_arrow_right.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icon/icon_cross.svg";
import { ReactComponent as ExternalIcon } from "../../assets/images/icon/icon_arrow_external.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/icon/icon_success.svg";
import { ReactComponent as FacebookIcon } from "../../assets/images/icon/icon_social_facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/images/icon/icon_social_twitter.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/images/icon/icon_social_linkedin.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/icon/icon_search.svg";
import { motion } from "framer-motion";

// Button
// Needs handleclick
// Needs active state
// Needs disabled state
// Needs className styles
// Needs some Icons

// Animations
//

const buttonVariants = {
  active: {},
  initial: { y: 0 },
  disabled: {},
  initialHover: {
    transition: { type: "ease-in-out", duration: 0.25 },
  },
  tap: {},
};

const Button = ({
  className,
  id,
  icon,
  iconAnimation,
  label,
  onClick,
  active,
  disabled,
}) => {
  return (
    <motion.button
      className={`button button--default ${className} ${
        active ? "button--active" : disabled ? "button--disabled" : ""
      }`}
      id={id}
      onClick={onClick}
      disabled={disabled}
      animate={active ? "active" : disabled ? "disabled" : "initial"}
      initial="initial"
      whileHover={active ? "activeHover" : !disabled ? "initialHover" : ""}
      whileTap={disabled ? "" : "tap"}
      variants={buttonVariants}
    >
      <ButtonIcon icon={icon}></ButtonIcon>
      {label && <span>{label}</span>}
    </motion.button>
  );
};

const ButtonIcon = ({ icon }) => {
  const iconVariants = {
    initial: { rotate: 0 },
    initialHover: {
      rotate: 0,
      transition: { duration: 0.5 },
    },
    active: { rotate: 0, transition: { type: "linear", bounce: 0.65 } },
  };

  return (
    <motion.div variants={iconVariants} className="flx">
      {icon == "plus" && <PlusIcon className="icon"></PlusIcon>}
      {icon == "arrow" && <ArrowIcon className="icon"></ArrowIcon>}
      {icon == "cross" && <CrossIcon className="icon"></CrossIcon>}
      {icon == "external" && <ExternalIcon className="external"></ExternalIcon>}
      {icon == "check" && <CheckIcon className="icon"></CheckIcon>}
      {icon == "search" && <SearchIcon className="icon"></SearchIcon>}
      {icon == "social_facebook" && (
        <FacebookIcon className="icon--social"></FacebookIcon>
      )}
      {icon == "social_twitter" && (
        <TwitterIcon className="icon--social"></TwitterIcon>
      )}
      {icon == "social_linkedin" && (
        <LinkedinIcon className="icon--social"></LinkedinIcon>
      )}
    </motion.div>
  );
};

export default Button;
