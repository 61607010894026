import React from "react";
import Blocks from "./Blocks";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const animateIn = {
  initial: { opacity: 0, y: 0},
  intro: {
    opacity: 1,
    y: 0
  },
  outro: {
    opacity: 0,
    y: 10
  },
};

const PageBlocks = ({ blocks }) => {

  return blocks && blocks.length > 0 ? (
    <div className="page__blocks">{blocks.map((block) => Blocks(block))}</div>
  ) : null;
};

export const ArticleBlocks = ({ columnBlocks }) => {
  const [ref, isVisible] = useInView({ threshold: 0 });
  return columnBlocks && columnBlocks.length > 0 ? (
    <motion.div
            className="article__blocks"
            ref={ref}
            initial="initial"
            variants={animateIn}
            animate={isVisible ? "intro" : false}
            transition={{
              delay: 0,
              duration: 0.2
            }}
          >
    {columnBlocks.map((block) => Blocks(block))}
    </motion.div>
  ) : null;
};

export default PageBlocks;
