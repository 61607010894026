import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import usePage from "../../hooks/usePage";
import useSearchFilter from "../../hooks/useSearchFilter"; // Path to your custom hook
import { useLanguage } from "../../hooks/useLanguage";

import { Main } from "../../components/Main";
import PageBlocks from "../../components/_common/Page/PageBlocks";
import Card from "../../components/Card";

import { LayoutGroup, motion } from "framer-motion";
import { useSearch } from "../../contexts/Search";

const SearchResultsPage = (landingspage) => {
  const [searchParams] = useSearchParams();
  const { page } = usePage(landingspage);
  const { searchValue, updateSearchValue, updateSearchOpen } = useSearch();
  const { filteredResults } = useSearchFilter(searchParams.get('search') || "");
  const { currentLanguage } = useLanguage();

  // Set the page color after page fetch
  useEffect(() => {
    page &&
      document.body.style.setProperty(
        "--theme-color",
        `var(--${page.pagecolor})`
      );
  }, [page]);

  useEffect(() => {
    updateSearchValue(searchParams.get('search'));
    updateSearchOpen(true);
  }, [searchParams]);

  let noResults = currentLanguage.code === "nl" ? "Geen artikelen gevonden" : "No articles found";
  
  return (
    page && (
      <>
        <Main>
          <PageBlocks {...page} />
          <div>
            <div className="headline headline--search">
              <h3>{page.title}</h3>
              <div className="headline__tags button button--default">
                <i className="icon icon-cross"></i>
                <span>{searchValue}</span>
              </div>
            </div>
            {filteredResults.length > 0 ? (
              <div className="article-grid">
                <div className="grid--search">
                  <LayoutGroup type="crossfade">
                    {filteredResults.map((article) => (
                      <AnimatedCard
                        key={article.uri}
                        article={article}
                        layoutId={article.uri}
                        layout
                      ></AnimatedCard>
                    ))}
                  </LayoutGroup>
                </div>
              </div>
            ) : (
              <div className="article-grid">
                <h3>{ noResults }</h3>
              </div>
            )}
          </div>
        </Main>
      </>
    )
  );
};

const AnimatedCard = ({ article }) => {
  const cardAnim = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        ease: [0.2, 0, 0, 1],
      },
    },
    exit: {
      opacity: 0,
    },
  };

  return (
    <motion.div
      layout
      className="card--holder"
      initial="initial"
      animate="animate"
      variants={cardAnim}
    >
      <Card
        className="card article-grid--card"
        article={article}
        ratioSize=""
        layout
      />
    </motion.div>
  );
};

export default SearchResultsPage;
