import classNames from "classnames";
import React from "react";

const Divider = ({ isVertical = false, color = "black", animated = false }) => {
  const cls = classNames({
    "divider-horizontal": !isVertical,
    "divider-vertical": isVertical,
    "divider--animated": animated
  });

  return <div className={cls} style={{ backgroundColor: color }} />;
};

export default Divider;
