import React, { useEffect, useCallback, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { useApplication } from "../contexts/Application";
// import { useSiteLanguage } from "../contexts/Language";
import useLocalStorage from "./useLocalStorage";

export const useLanguage = () => {
  // Fetch the languages available in Kirby
  const { site, triggerFetch } = useApplication();

  const languages = site && site.languages;
  // Set the default language to the default Kirby Language
  const defaultLang =
    languages && languages.filter((language) => language.isDefault == true);

  // On change of the currentLanguage update Application Context Language state
  const [currentLanguage, setCurrentLanguage] = useLocalStorage("language", {
    code: "nl",
    isDefault: true,
    name: "Nederlands",
  });

  // If the language is changed then add code to document
  useEffect(() => {
    document.documentElement.setAttribute("lang", currentLanguage.code);
    triggerFetch();
  }, [currentLanguage, triggerFetch]);

  // Exports needed for updating & filtering
  return { languages, currentLanguage, setCurrentLanguage, defaultLang };
};
