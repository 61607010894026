import React, { useState, useEffect } from "react";
import { TagButton } from "../TagButton";
import { LanguageButton } from "../LanguageButton";
import { useApplication } from "../../contexts/Application";
import { useLanguage } from "../../hooks/useLanguage";
import Divider from "../Divider";
import { Link } from "react-router-dom";
import { ReactComponent as ExternalIcon } from "../../assets/images/icon/icon_arrow_external.svg";

export const FilterNavbar = ({ tags, open }) => {
  useEffect(() => {
    open
      ? document.body.classList.add("filter-open")
      : document.body.classList.remove("filter-open");
  }, [open]);

  return (
    <>
      <div className="filter-navbar">
        <div className="filter-navbar__holder">
          {tags &&
            tags.map((tag, i) =>
              Object.keys(tag).map(
                (section) =>
                  tag[section].buttons[0].length > 0 && (
                    <div key={i} className="filter-navbar__section">
                      <h4>{tag[section].title}</h4>
                      {tag[section].buttons && (
                        <div className="filter-navbar__section__tags">
                          {tag[section].buttons[0].map((button, i) => (
                            <TagButton key={i} button={button}></TagButton>
                          ))}
                        </div>
                      )}
                    </div>
                  )
              )
            )}
          <LanguageSwitcher></LanguageSwitcher>
        </div>
      </div>
    </>
  );
};

function refreshPage() {
  window.location.reload(false);
}

const LanguageSwitcher = () => {
  const { site } = useApplication();
  const { languages, currentLanguage, setCurrentLanguage } = useLanguage();
  const appLanguage = currentLanguage && currentLanguage;

  // Handle Click
  const handleClick = (selectedLanguage) => {
    setCurrentLanguage(selectedLanguage);
    refreshPage();
  };

  // Active state
  const isActive = (buttonLanguage) => {
    if (appLanguage.code == buttonLanguage.code) {
      return true;
    }
  };
  return (
    <>
      <Divider animated="true"></Divider>
      <div className="filter-navbar__section">
        <h4>{site.langswitchlabel}</h4>
        <div className="filter-navbar__section__tags">
          {languages &&
            languages.map((language, i) => (
              <LanguageButton
                key={i}
                buttonLanguage={language}
                isActive={isActive}
                handleClick={handleClick}
              ></LanguageButton>
            ))}
        </div>
      </div>
    </>
  );
};
