import React, { useEffect } from "react";
import usePage from "../../hooks/usePage";
import HeroContentpage from "../../components/HeroContentpage";
import { useLocation } from "react-router";
import PageBlocks, {
  ArticleBlocks,
} from "../../components/_common/Page/PageBlocks";
import { Main } from "../../components/Main";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import Blocks from "../../components/_common/Page/PageBlocks/Blocks";

const animateIn = {
  initial: { opacity: 0, y: 100 },
  intro: {
    opacity: 1,
    y: 0,
  },
  outro: {
    opacity: 0,
    y: 100,
  },
};

const Default = (landingPage) => {
  const { page } = usePage(landingPage);
  const layoutPage = page && page.hasOwnProperty("layouts");
  const layouts = layoutPage && page.layouts.data;
  const [ref, isVisible] = useInView({ threshold: 0.5 });

  // Set the page color after page fetch
  useEffect(() => {
    const wrapper = document.querySelector("#wrapper");
    page &&
      page.pagecolor &&
      document.body.style.setProperty(
        "--theme-color",
        `var(--${page.pagecolor})`
      );
  }, [page]);

  return (
    page && (
      <>
        {layoutPage == false && (
          <Main>
            <PageBlocks {...page} />
          </Main>
        )}
        {layoutPage == true && (
          <>
            <Main className="article">
              <motion.div
                className=""
                ref={ref}
                initial="initial"
                variants={animateIn}
                animate={isVisible ? "intro" : false}
                transition={{
                  delay: 0,
                  duration: 1,
                  ease: [0.2, 0, 0, 1],
                }}
              >
                <HeroContentpage content={page}></HeroContentpage>
              </motion.div>
              {layouts &&
                Object.values(layouts).map((layout, i) => (
                  <LayoutSection layout={layout} key={i}></LayoutSection>
                ))}
            </Main>
            {page.slider?.length > 0 && (
              <div className="article--section__slider">
                {page.slider.map((block, i) => Blocks(block))}
              </div>
            )}
          </>
        )}
      </>
    )
  );
};

const LayoutSection = ({ layout }) => {
  return (
    <section className="article--section">
      {layout &&
        Object.values(layout.columns.data).map((column, i) => (
          <LayoutColumn column={column} key={i}></LayoutColumn>
        ))}
    </section>
  );
};

const LayoutColumn = ({ column }) => {
  const columnwidth = column.columnWidth.replace("/", "__");
  return (
    <>
      {column && (
        <div className={`column--${columnwidth}`}>
          <ArticleBlocks {...column} />
        </div>
      )}
    </>
  );
};

export default Default;
