import React from "react";
import { useApplication } from "../../contexts/Application";
import Socials from "../Socials";
import Divider from "../Divider";
import Footnote from "../Footnote";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ReactComponent as ExternalIcon } from "../../assets/images/icon/icon_arrow_external.svg";
import CookieConsent from "../CookieConsent";

const animateIn = {
  initial: { opacity: 0, y: 0 },
  intro: {
    opacity: 1,
    y: 0,
  },
  outro: {
    opacity: 0,
    y: 0,
  },
};

const Footer = ({ remarks, advices, newsletter, form, socials }) => {
  const { site } = useApplication();
  const [ref, isVisible] = useInView({ threshold: 0.1 });
  const { wrapperSections } = site || { wrapperSections: [] };
  const footer = wrapperSections.filter(
    ({ type }) => type == "wrapperpageFooter"
  )[0].content;

  return (
    <>
      <motion.footer
        ref={ref}
        className="footer"
        initial="initial"
        variants={animateIn}
        animate={isVisible ? "intro" : false}
        transition={{
          delay: 0,
          duration: 0.2,
        }}
      >
        <Divider className=""></Divider>
        <div className="grid">
          {footer.remarkstitle && (
            <div className="footer__remarks footer__column">
              <>
                {footer.remarkstitle && (
                  <h3 className="footer-title">{footer.remarkstitle}</h3>
                )}
                {footer.remarkscontent && (
                  <p
                    className="footer-subtitle"
                    dangerouslySetInnerHTML={{ __html: footer.remarkscontent }}
                  />
                )}
              </>
              {footer.socials && <Socials buttons={footer.socials} />}
            </div>
          )}
          {footer && (
            <div className="footer__advices footer__column">
              <>
                {footer.advicestitle && (
                  <h3 className="footer-title">{footer.advicestitle}</h3>
                )}
                {footer.advicescontent && (
                  <p
                    className="footer-subtitle"
                    dangerouslySetInnerHTML={{ __html: footer.advicescontent }}
                  />
                )}
              </>
            </div>
          )}
          {footer && (
            <div className="footer__newsletter footer__column">
              <>
                {footer.newslettertitle && (
                  <h3 className="footer-title">{footer.newslettertitle}</h3>
                )}
                {footer.newslettercontent && (
                  <p
                    className="footer-subtitle"
                    dangerouslySetInnerHTML={{
                      __html: footer.newslettercontent,
                    }}
                  />
                )}
              </>
            </div>
          )}
        </div>
        <ExternalNavButtons></ExternalNavButtons>
        <Footnote></Footnote>
      </motion.footer>
    </>
  );
};

const ExternalNavButtons = () => {
  const { site } = useApplication();
  const { wrapperSections } = site || { wrapperSections: [] };
  const checkTranslation = wrapperSections.filter(
    ({ type }) => type == "wrapperpageFooterLinks"
  ).length
    ? true
    : false;

  const navLinks = checkTranslation
    ? wrapperSections.filter(({ type }) => type == "wrapperpageFooterLinks")[0]
        .content
    : "";

  const links = navLinks.externallinks;

  return (
    <>
      {links && links.length > 0 && (
        <div className="footer-navbar__section">
          <>
            <h3>{navLinks.linkslabel}</h3>
            <div className="footer-navbar__section__tags">
              {links.map((link, i) => (
                <Link
                  className="button button--default"
                  key={i}
                  to={link.linkslink.value}
                  target={link.target === "true" ? "_blank" : ""}
                >
                 {link.target === "true" && ( <div className="flx">
                    <ExternalIcon className="icon"></ExternalIcon>
                  </div>)}
                  <span>{link.linkstext}</span>
                </Link>
              ))}
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default Footer;
