import React, { useEffect, useRef } from "react";

const Image = ({ url, alt, caption }) => {
  return (
    <figure className="image--container">
      {url && <img className="image" src={url} alt={`${alt}`} />}
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  );
};

export const AspectImage = ({ image }) => {
  const holder = useRef();
  const imgRatio = image && image.ratio && image.ratio;

  useEffect(() => {
    imgRatio &&
      holder.current.style.setProperty("--aspect-ratio", `${imgRatio}`);
  }, [imgRatio]);

  return (
    <figure>
      <div
        className={`image--holder ${imgRatio ? "image--ratio" : ""}`}
        ref={holder}
      >
        {image.url && (
          <img
            className={`img--fluid ${
              imgRatio ? "background--image img--cover" : ""
            }`}
            src={`${image.url ? image.url : image}`}
            alt={imgRatio}
            loading="lazy"
          ></img>
        )}
      </div>
      {image.caption && <figcaption><small>{image.caption}</small></figcaption>}
    </figure>
  );
};

export default Image;
