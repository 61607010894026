import React, { createContext, useState } from "react";

// Create the context
const SearchContext = createContext();

// Create a provider component
const SearchProvider = ({ children }) => {
  // Define the state for the search value
  const [searchValue, setSearchValue] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  // Create a function to update the search value
  const updateSearchValue = (value) => {
    setSearchValue(value);
  };

  const resetSearchValue = () => {
    setSearchValue("");
  };

  const updateIsSearch = (value) => {
    setIsSearch(value);
  };

  const updateSearchOpen = (value) => {
    setSearchOpen(value);
  };

  // Provide the search value and update function to the children components
  return (
    <SearchContext.Provider
      value={{
        searchValue,
        isSearch,
        searchOpen,
        updateSearchValue,
        resetSearchValue,
        updateIsSearch,
        updateSearchOpen,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

function useSearch() {
  const context = React.useContext(SearchContext);
  if (context === undefined) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
}

export { SearchProvider, useSearch };
