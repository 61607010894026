import React from "react";
import { AspectImage } from "../Image";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import Badge from "../Badge";
import { useTags } from "../../contexts/SelectedTags";

const animateIn = {
  initial: { opacity: 0, y: 50 },
  intro: {
    opacity: 1,
    y: 0,
  },
  outro: {
    opacity: 0,
    y: 50,
  },
};

const animateInImage = {
  initial: { opacity: 0, y: 50, height: "auto" },
  intro: {
    opacity: 1,
    y: 0,
  },
  outro: {
    opacity: 0,
    y: 50,
  },
};

const HeroContentpage = ({ content }) => {
  const [ref, isVisible] = useInView({ threshold: 0.1 });
  const { setSelectedTags } = useTags();

  const heroImage = {
    url: content.primaryImage,
    ratio: "4/3",
  };

  const handleClick = () => {
    setSelectedTags([]);
  };

  const tags =
    content.generaltags &&
    content.generaltags.concat(
      content.eventTags,
      content.themestags,
      content.disciplinestags,
      content.abouttags
    );

  return (
    content && (
      <div className={`hero-contentpage ${!heroImage.url && "no-cover"}`}>
        <motion.div
          className="hero-contentpage__titles"
          ref={ref}
          initial="initial"
          variants={animateIn}
          animate={isVisible ? "intro" : false}
          transition={{
            delay: 0,
            duration: 1,
            ease: [0.2, 0, 0, 1],
          }}
        >
          <h1 className="display--1">{content.title}</h1>
          <p className="subtitle">{content.subtitle}</p>
          {tags && (
            <div className="card__container__tags scrollbar-hidden">
              {tags.map((badge, i) => (
                <Link key={i} to={`/${badge}`} onClick={handleClick}>
                  <Badge className="" key={i} icon={badge.icon} label={badge} />
                </Link>
              ))}
            </div>
          )}
        </motion.div>
        {heroImage.url && (
          <motion.div
            className="hero-contentpage__cover"
            ref={ref}
            initial="initial"
            variants={animateInImage}
            animate={isVisible ? "intro" : false}
            transition={{
              delay: 0.2,
              duration: 1,
              ease: [0.2, 0, 0, 1],
            }}
          >
            <AspectImage image={heroImage} />
          </motion.div>
        )}
      </div>
    )
  );
};

export default HeroContentpage;
