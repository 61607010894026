import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useReducer,
} from "react";

const initialState = {
  selectedTags: [],
};

const TagsContext = createContext();

function TagsReducer(state, action) {
  switch (action.type) {
    case "SET_SELECTEDTAG":
      return {
        ...state,
        selectedTags: [...state.selectedTags, action.payload],
      };
    case "REMOVE_SELECTEDTAG":
      return {
        ...state,
        selectedTags: state.selectedTags.filter((tag) => tag != action.payload),
      };
    case "REMOVE_SELECTEDTAGS":
      return {
        ...state,
        selectedTags: [],
      };
    case "REPLACE_SELECTEDTAGS":
      return {
        state,
        selectedTags: action.payload,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function TagsProvider({ children }) {
  const [state, dispatch] = useReducer(TagsReducer, initialState);
  const [filterOpen, setFilterOpen] = useState(false);
  const setSelectedTag = (selectedtag) => {
    dispatch({ type: "SET_SELECTEDTAG", payload: selectedtag });
  };

  const removeSelectedTag = (selectedtag) => {
    dispatch({ type: "REMOVE_SELECTEDTAG", payload: selectedtag });
  };

  const setSelectedTags = (selectedtags) => {
    dispatch({ type: "REPLACE_SELECTEDTAGS", payload: selectedtags });
  };

  const removeSelectedTags = (selectedtags) => {
    dispatch({ type: "REMOVE_SELECTEDTAGS", payload: { selectedtags } });
  };

  const value = {
    ...state,
    setSelectedTag,
    setSelectedTags,
    removeSelectedTag,
    removeSelectedTags,
    filterOpen,
    setFilterOpen,
  };
  return <TagsContext.Provider value={value}>{children}</TagsContext.Provider>;
}

function useTags() {
  const context = useContext(TagsContext);
  if (context === undefined) {
    throw new Error("useTags must be used within a TagsProvider");
  }
  return context;
}

export { TagsProvider, useTags };
