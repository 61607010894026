import React, { useEffect } from "react";
import { useTags } from "../../contexts/SelectedTags";
import { useNavigate } from "react-router-dom";
import usePage from "../../hooks/usePage";
import HeroContentpage from "../HeroContentpage";
import { Main } from "../Main";
import PageBlocks, { ArticleBlocks } from "../_common/Page/PageBlocks";
import { ReactComponent as EmailIcon } from "../../assets/images/icon/icon_social_mail.svg";
import { ReactComponent as FacebookIcon } from "../../assets/images/icon/icon_social_facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/images/icon/icon_social_twitter.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/images/icon/icon_social_linkedin.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/images/icon/icon_social_whatsapp.svg";
import { ReactComponent as WeiboIcon } from "../../assets/images/icon/icon_social_weibo.svg";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WeiboShareButton
} from "react-share";

const Article = () => {
  const { page } = usePage();
  const { selectedTags, setFilterOpen } = useTags();
  const history = useNavigate();

  const layouts = page && page.layouts?.data;

  // Set the page color after page fetch
  useEffect(() => {
    setFilterOpen(false);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const wrapper = document.querySelector("#wrapper");
    page &&
      page.pagecolor &&
      document.body.style.setProperty("--theme-color", `var(--${page.pagecolor})`);
  }, [page]);

  useEffect(() => {
    const filterParams = selectedTags.toString().replace(",", "/");
    selectedTags.length >= 1 && history(`/${filterParams}`);
  }, [selectedTags, history]);

  return (
    <>
      {page && (
        <><Main className="article">
          <HeroContentpage content={page}></HeroContentpage>
          {layouts &&
            Object.values(layouts).map((layout, i) => (
              <LayoutSection layout={layout} key={i}></LayoutSection>
            ))}
          </Main></>
      )}
      <div className="article-share">
        <div className="article-share__link">
          <h3 className="article-share__link-label mb-0">Deel dit artikel</h3>
        </div>
        <div className="socials">
          <EmailShareButton 
            url={window.location.href}
            subject={page && page.title}
            body={page && page.subtitle}
            className="button button--default button--circle" 
            resetButtonStyle={false}>
            <EmailIcon/>
          </EmailShareButton>
          <FacebookShareButton url={window.location.href} className="button button--default button--circle" resetButtonStyle={false}>
            <FacebookIcon/>
          </FacebookShareButton>
          <LinkedinShareButton url={window.location.href} className="button button--default button--circle" resetButtonStyle={false}>
            <LinkedinIcon/>
          </LinkedinShareButton>
          <TwitterShareButton url={window.location.href} className="button button--default button--circle" resetButtonStyle={false}>
            <TwitterIcon/>
          </TwitterShareButton>
          <WhatsappShareButton url={window.location.href} className="button button--default button--circle" resetButtonStyle={false}>
            <WhatsappIcon/>
          </WhatsappShareButton>
          <WeiboShareButton url={window.location.href} className="button button--default button--circle" resetButtonStyle={false}>
            <WeiboIcon/>
          </WeiboShareButton>
        </div>
      </div>

      {page && (
        <>
         {page.blocks?.length > 0 &&
            <div className="article--section__slider">
              <PageBlocks {...page} />
            </div>
          }
          </>
      )}
    </>
  );
};

const LayoutSection = ({ layout }) => {
  return (
    <section className="article--section">
      {layout &&
        Object.values(layout.columns.data).map((column, i) => (
          <LayoutColumn column={column} key={i}></LayoutColumn>
        ))}
    </section>
  );
};

const LayoutColumn = ({ column }) => {
  const columnwidth = column.columnWidth.replace("/", "__");
  return (
    <>
      {column && (
        <div className={`column--${columnwidth}`}>
          <ArticleBlocks {...column} />
        </div>
      )}
    </>
  );
};

export default Article;
