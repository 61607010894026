import React, { useState, useEffect } from "react";
import { useApplication } from "../../contexts/Application";
import { useCookies } from 'react-cookie';
import { motion } from "framer-motion";
import Button from "../Button";

const variants = {
  initial: { y: '110%'},
  intro: {
    y: 0
  },
  outro: {
    y: '110%'
  }
};

const CookieConsent = () => {
  const { site } = useApplication();
  const { wrapperSections } = site || { wrapperSections: [] };
  const cookieConsent = wrapperSections.filter(
    ({ type }) => type == "wrapperpageCookieConsent"
  )[0].content;

  const [cookies, setCookie] = useCookies([process.env.COOKIE_NAME]);
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    // If no cookie is set yet, show the consent
    if (cookies && cookies[process.env.COOKIE_NAME]?.consent !== undefined) {
      setShowConsent(false); 
    } else {
      setShowConsent(true);
    }
  }, [cookies]);

  const onAccept = () => {
    setCookie(process.env.COOKIE_NAME, { consent: true }, { path: '/' });
  };

  const onDecline = () => {
    setCookie(process.env.COOKIE_NAME, { consent: false }, { path: '/' });
  };

  return (
    <motion.div
      className="cookie-consent"
      initial="initial"
      variants={variants}
      animate={(showConsent && cookieConsent) ? "intro" : "outro" }
      transition={{
        delay: 0.2,
        duration: 0.5,
        ease: [0.2, 0, 0, 1]
      }}
    >
      <h3>{ cookieConsent.header }</h3>
      <p>{ cookieConsent.message }</p>

      <div className="cookie-consent__buttons">
        <Button
          className={"button--filter button--sm"}
          label={ cookieConsent.declinetext }
          onClick={ onDecline }
        />
        <Button
          className={"button--filter button--active button--sm"}
          label={ cookieConsent.accepttext }
          onClick={ onAccept }
        />
      </div>

    </motion.div>
  );
};

export default CookieConsent;
