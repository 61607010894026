import React from "react";

export const Quote = ({ content }) => {
  return (
    <blockquote>
      <h2 dangerouslySetInnerHTML={{ __html: content.text }}></h2>
      <p dangerouslySetInnerHTML={{ __html: content.citation }}></p>
    </blockquote>
  );
};

export default Quote;
